<template>
  <div>

    <b-form @submit.stop.prevent="formOnsubmit">

      <!-- Name input -->
      <b-form-group
        id="input-group-name"
        label="Nama:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Satuan"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group
        id="input-group-description"
        label="Deskripsi:"
        label-for="input-description"
      >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Deksripsi Satuan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/uoms')"
      >
        Batal
      </b-button>

    </b-form>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    form: Object,
    route: String
  },

  data() {
    return {
      // Error
      error: {
        name: '',
        description: ''
      }
    }
  },

  methods: {

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/masters/uoms')
      }
    }

  }

}
</script>

<style>
</style>